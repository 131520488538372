var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-data-table',{staticClass:"table-rounded",attrs:{"headers":_vm.headers,"items":_vm.orderList,"single-expand":true,"expanded":_vm.expanded,"show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td'),(item.same_as_order.length>0)?_c('td',{attrs:{"colspan":_vm.headers.length-1}},[_c('same-molecules',{attrs:{"preOrderMols":item.same_as_order}})],1):_vm._e()]}},{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
return [(item.same_as_order.length>0)?_c('v-icon',{attrs:{"color":"green darken-2"},on:{"click":function($event){return _vm.toggleExpand(item)}}},[_vm._v(" "+_vm._s(_vm.expandIcon)+" ")]):_vm._e()]}},{key:"item.current_status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-medium",attrs:{"small":"","color":_vm.statusColor[item.current_status]}},[_vm._v(" "+_vm._s(item.current_status)+" ")])]}},{key:"item.payment_status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-medium",attrs:{"small":"","color":_vm.statusColor[item.payment_status]}},[_vm._v(" "+_vm._s(item.payment_status)+" ")])]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [(item.report_status !== 'Completed')?_c('span',[_vm._v(" "+_vm._s(item.report_status)+" ")]):_vm._e(),(item.report_status === 'Completed')?_c('v-icon',{attrs:{"color":"green darken-2"},on:{"click":function($event){return _vm.download(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiDownload)+" ")]):_vm._e()]}},{key:"item.upload_id",fn:function(ref){
var item = ref.item;
return [(item.report_status !== 'Completed')?_c('span',[_vm._v(" - ")]):_vm._e(),(item.report_status === 'Completed')?_c('v-icon',{attrs:{"color":"green darken-2"},on:{"click":function($event){return _vm.uploadReport(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiUpload)+" ")]):_vm._e()]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.simpleFormatDate(item.createdAt))+" ")]}}])}),(_vm.alertMsg.length > 1)?_c('v-divider',{staticClass:"my-2"}):_vm._e(),(_vm.alertMsg.length > 1)?_c('v-alert',{attrs:{"type":_vm.alertType,"dismissible":""}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.alertMsg)}})]):_vm._e(),_c('form',{attrs:{"id":"_uploadReportForm"}},[_c('input',{staticStyle:{"display":"none"},attrs:{"type":"file","id":"report_to_upload","name":"report_to_upload","accept":".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"}})]),_c('v-dialog',{attrs:{"fullscreen":""},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('v-container',{staticStyle:{"background-color":"rgba(255, 255, 255, 0.5)"},attrs:{"fluid":"","fill-height":""}},[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }