<template>
  <v-card>
      <span v-if="!preOrderMols.length" class="error--text pl-10"> Did not find same molcules for this order </span>
    <v-treeview
      :items="makeTreeItems"
      v-if="preOrderMols.length"
      dense
    >
      <template v-slot:label="{ item }">
        
        {{ item.name }} &nbsp;&nbsp;&nbsp;

        <v-icon
          color="green darken-2"
          @click="downloadReport(item)"
          v-if="item.name.indexOf('Order id') != -1"
        >
          {{ icons.mdiDownload }} 
        </v-icon>
      </template>
    </v-treeview>
  </v-card>
</template>

<script>
import { mdiDownload } from '@mdi/js'
import { ref, computed } from '@vue/composition-api'

import { getDownloadReportURL } from "../../../services/apiCall.js";
import { openExternalURL } from "../../../services/utils.js";

export default {
  props: {
    preOrderMols: { type: Array, default: [] },
  },
  setup(props) {
    // const sameMolItems = ref([])

    const makeTreeItems = computed(() => {
      let sameMolItems = []
      for (let i=0; i<props.preOrderMols.length; i++) {
        let sameMol = props.preOrderMols[i]
        let mol = {
          id: sameMol.serial_no,
          name: sameMol.molecule_name,
          children: sameMol.same_mols.map((x) => {
            return {
              id: x.serial_no,
              client_id: x.client_id,
              order_id: x.order_id,
              name: x.serial_no +  ') ' + x.molecule_name + ' - Order id: ' + x.order_id
            }
          }),
        };
        sameMolItems.push(mol)
      }
      return sameMolItems
    })

    const downloadReport = async (item) => {
      let downloadURL = await getDownloadReportURL({ client_id: item.client_id, order_id: item.order_id });
      openExternalURL(downloadURL);
    }

    return {
      makeTreeItems,
      downloadReport,
      getDownloadReportURL,
      openExternalURL,
       // icons
      icons: {
        mdiDownload,
      },
    }
  },
}

</script>