<template>
  <v-card>
    <v-data-table
      :headers="headers"
      :items="orderList"
      :single-expand="true"
      :expanded.sync="expanded"
      class="table-rounded"
      show-expand
    >
      <template v-slot:expanded-item="{ item }">
        <td></td>
        <td :colspan="headers.length-1" v-if="item.same_as_order.length>0">
          <same-molecules :preOrderMols="item.same_as_order"></same-molecules>
        </td>
      </template>
      <template v-slot:item.data-table-expand="{ item }">
        <v-icon
          color="green darken-2"
          @click="toggleExpand(item)"
          v-if="item.same_as_order.length>0"
        >
          {{ expandIcon }} 
        </v-icon>
      </template>
      <template v-slot:item.current_status="{ item }">
        <v-chip
          small
          :color="statusColor[item.current_status]"
          class="font-weight-medium"
        >
          {{ item.current_status }}
        </v-chip>
      </template>
      <template v-slot:item.payment_status="{ item }">
        <v-chip
          small
          :color="statusColor[item.payment_status]"
          class="font-weight-medium"
        >
          {{ item.payment_status }}
        </v-chip>
      </template>
      <template v-slot:item.id="{ item }">
        <span v-if="item.report_status !== 'Completed'"> {{ item.report_status }} </span>
        <v-icon
          color="green darken-2"
          @click="download(item)"
          v-if="item.report_status === 'Completed'"
        >
          {{ icons.mdiDownload }} 
        </v-icon>
      </template>

      <template v-slot:item.upload_id="{ item }">
        <span v-if="item.report_status !== 'Completed'"> - </span>
        <v-icon
          color="green darken-2"
          @click="uploadReport(item)"
          v-if="item.report_status === 'Completed'"
        >
          {{ icons.mdiUpload }} 
        </v-icon>
      </template>

      <template v-slot:item.createdAt="{ item }">
        {{ simpleFormatDate(item.createdAt) }}
      </template>
    </v-data-table>
        
    <v-divider class="my-2" v-if="alertMsg.length > 1"></v-divider>    
    <v-alert
      :type="alertType"
      v-if="alertMsg.length > 1"
      dismissible
    > 
      <span v-html="alertMsg"></span>
    </v-alert>
    <form id="_uploadReportForm">
      <input
        type="file"
        id="report_to_upload"
        name="report_to_upload"
        style="display: none"
        accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      />
    </form>
    <v-dialog v-model="loading" fullscreen>
      <v-container fluid fill-height style="background-color: rgba(255, 255, 255, 0.5);">
        <v-layout justify-center align-center>
          <v-progress-circular
            indeterminate
            color="primary">
          </v-progress-circular> 
        </v-layout>
      </v-container>
    </v-dialog>
  </v-card>
</template>

<script>
import { mdiDownload, mdiUpload, mdiChevronUp, mdiChevronDown } from '@mdi/js'
import { ref } from '@vue/composition-api'

import { listClientOrders, getDownloadReportURL, reportUpload } from "../../../services/apiCall.js";
import { openExternalURL, simpleFormatDate } from "../../../services/utils.js";
import SameMolecules from './SameMolecules.vue'

export default {
  components: {
    SameMolecules
  },
  setup(props) {
    return {
      listClientOrders,
      getDownloadReportURL,
      reportUpload,
      openExternalURL,
      simpleFormatDate,
    }
  },
  data: () => ({
    alertMsg: '',
    alertType: 'success',
    headers: [
      { text: '', value: 'data-table-expand', width: "2%" },
      { text: 'Client Name', value: 'short_name', width:"15%" },
      { text: 'Order Title', value: 'order_heading', width:"15%" },
      { text: 'Order Date', value: 'createdAt', width:"15%" },
      { text: 'Molecules', value: 'molecule_count' },
      { text: 'Order Status', value: 'current_status' },
      { text: 'Payment Status', value: 'payment_status' },
      { text: 'Report Download', value: 'id', sortable: false, align: 'center' },
      { text: 'Report Upload', value: 'upload_id', sortable: false, align: 'center' },
    ],

    statusColor: {
      Reviewed: 'warning',
      Dilevered: 'info',
      Invoiced: 'warning',
      Received: 'success',
      Pending: 'warning',
      Partial: 'info',
      Completed: 'success',
    },

    expanded: [],

    orderList: [],
    loading: false,
    expandIcon: mdiChevronDown,

    // icons
    icons: {
      mdiDownload,
      mdiUpload,
    },
}),
  mounted: function() {
    this.init();
  },
  methods: {
    init() {
      this.getClientOrders();
    },
    getClientOrders() {
      this.listClientOrders({ 'include_molecule': true })
      .then(async (res) => {
        //console.log('list client orders called:', res);
        if (res.data.status === 0) {
          this.orderList = res.data.result.order_list;
          //add id to upload report column
          this.orderList = this.orderList.map((x) => {
            x['upload_id'] = x.id;
            x['same_as_order'] = [];
            for (let idx = 0; idx < x.molecule_list.length; idx++) {
              if (x.molecule_list[idx].same_as_order && x.molecule_list[idx].same_as_order.length > 0) {
                let sameMolRec = {
                  'molecule_name': x.molecule_list[idx].molecule_name,
                  'serial_no': x.molecule_list[idx].serial_no,
                  'same_mols': x.molecule_list[idx].same_as_order.map((xx) => {
                    return xx;
                  }),
                }
                x['same_as_order'].push(sameMolRec);
              }
            }            
            return x;
          });
        } else {
          this.alertType = "error";
          this.alertMsg = res.data.error.error_message;
        }
      })
      .catch(async (err) => {
        this.alertType = "error";
        this.alertMsg = "List client orders failed" + ": " + err.toString();
      });
    },

    async download(item) {
      let downloadURL = await this.getDownloadReportURL({ client_id: item.client_id, order_id: item.id });
      this.openExternalURL(downloadURL);
    },

    toggleExpand(item) {
      if (this.expanded[0] === item) {
        this.expanded = [];
        this.expandIcon = mdiChevronDown;
      } else {
        this.expanded.push(item);
        this.expandIcon = mdiChevronUp;
      }
    },

    async uploadReport(item) {
      const element = document.getElementById('report_to_upload');
      element.onchange = async (e) => {
        //console.log("upload Report:", element.file, e);

        this.loading = true;

        // upload the report
        reportUpload({ client_id: item.client_id, order_id: item.id, report_content: element.files }).then((res) => {
          //console.log('[uploadReport]', res);
          if (res.data.status === 0) {
            this.alertType = "success";
            this.alertMsg = "Report file uploaded successfully";
            this.getClientOrders();
          } else {
            this.alertType = "error";
            this.alertMsg = "Some error while uploading report file";
          }
          this.loading = false;
        }).catch((err) => {
          console.log('[uploadReport] - err', err);
          this.alertType = "error";
          this.alertMsg = "Some error while uploading report file";
          this.loading = false;
        });
      };
      element.click();

    },
  },
}
</script>
